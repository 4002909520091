import root from 'window-or-global';

export default function trackPageView(url) {
  try {
    root.gtag('config', 'UA-XXXXXXXX-X', {
      page_location: url,
    });
  } catch (error) {
    // silences the error in dev mode
    // and/or if gtag fails to load
  }
}
