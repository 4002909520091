import React, { useCallback, useEffect, useState } from 'react';
import sleep from '../../_helpers/sleep';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import { SnackbarOptions, StackItem } from './types';

interface Props {
  message: string;
  options: SnackbarOptions;
  id: number;
  setStack: any;
  classes: any;
}

function SnackBarItem({ classes, message, id, setStack }: Props) {
  const [onMount, setOnMount] = useState(false);

  const delay = useCallback(
    async function() {
      await sleep(10);
      setOnMount(true);
      await sleep(2000);
      setOnMount(false);
      await sleep(400);
      setStack(prevStack => {
        return prevStack.reduce((acc, item) => {
          return item.id === id ? acc : [...acc, item];
        }, [] as StackItem[]);
      });
    },
    [setStack, setOnMount],
  );

  useEffect(() => {
    delay();
  }, [delay, setOnMount]);

  return <div className={classNames(classes.root, { [classes.onMount]: onMount })}>{message} </div>;
}

const styles = theme => {
  return {
    root: {
      position: 'relative',
      maxWidth: 300,
      left: -300,
      background: theme.palette.secondary.light,
      transition: 'left .4s',
      padding: theme.spacing.unit * 2,
      color: theme.palette.common.white,
      fontSize: 14,
      [theme.media.sm]: {
        left: '-100vw',
      },
    },
    onMount: {
      left: 10,
      [theme.media.sm]: {
        left: 0,
      },
    },
  };
};

export default injectSheet(styles)(SnackBarItem);
