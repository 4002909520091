import React, { useCallback, useState } from 'react';
import injectSheet from 'react-jss';
import SnackbarItem from './SnackbarItem';
import { SnackbarOptions, StackItem } from './types';
import SnackbarContext from './SnackbarContext';

// Components
import Portal from '../Portal';

interface Props {
  classes: any;
  children: React.ReactNode;
}

function SnackbarProvider({ children, classes }: Props) {
  const [stack, setStack] = useState<StackItem[]>([]);

  const addToSnackbar = useCallback(
    (message: string, options: SnackbarOptions) => {
      const newStack = { message, options, id: Math.random() };
      setStack(prevStack => [...prevStack, newStack]);
    },
    [setStack],
  );

  return (
    <SnackbarContext.Provider value={{ addToSnackbar }}>
      <>
        {children}
        <Portal open={true}>
          <div className={classes.root}>
            {stack.map(({ message, options, id }: StackItem) => {
              return (
                <SnackbarItem
                  message={message}
                  options={options}
                  id={id}
                  key={id}
                  setStack={setStack}
                />
              );
            })}
          </div>
        </Portal>
      </>
    </SnackbarContext.Provider>
  );
}

const styles = theme => {
  return {
    root: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      transition: 'all .4s',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.media.sm]: {
        alignItems: 'center',
      },
      '& > *': {
        marginBottom: theme.spacing.unit,
      },
    },
  };
};

export default injectSheet(styles)(SnackbarProvider);
