import App from 'next/app';
import React from 'react';
import Router from 'next/router';
import { JssProvider, ThemeProvider } from 'react-jss';
import { Provider as ReduceProvider } from 'react-redux';
import createStore from '../store/createStore';
import * as Sentry from '@sentry/browser';
import SnackbarProvider from '../components/Snackbar';
import MountStore from '../components/MountStore';
import theme from '../styles/theme';
import trackPageView from '../_helpers/trackPageView';
import 'lazysizes';

// Consts
const isProduction = process.env.NODE_ENV === 'production';
const store = createStore();

if (isProduction) {
  Sentry.init({ dsn: 'https://18b53d31faef401dbcca3c3fcfdacdda@sentry.io/1871834' });
}

class RootApp extends App {
  componentDidMount() {
    const style = document.getElementById('server-side-styles');

    if (style) {
      style.parentNode.removeChild(style);
    }

    Router.onRouteChangeComplete = url => {
      trackPageView(url);
    };
  }

  componentDidCatch(error, errorInfo) {
    const isProduction = process.env.NODE_ENV === 'production';
    if (isProduction) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });

      super.componentDidCatch(error, errorInfo);
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <ReduceProvider store={store}>
        <JssProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
              <MountStore>
                <Component {...pageProps} />
              </MountStore>
            </SnackbarProvider>
          </ThemeProvider>
        </JssProvider>
      </ReduceProvider>
    );
  }
}

export default RootApp;
