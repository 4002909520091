import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAccountLocalStorage } from '../../store/auth';

interface Props {
  children: React.ReactNode;
}

function MountStore({ children }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAccountLocalStorage());
  }, []);

  return children;
}

export default MountStore;
